import React from 'react'

export default function LoadingPage() {
    const characters = "Chargement en cours...".split('');

    return (
        <div className='flex flex-col justify-center items-center loadingPage'>
            <h1 className='flex justify-center max-sm:text-exsm'> {characters.map((char, index) => (
                <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
                    {char === ' ' ? '\u00A0' : char}
                </span>
            ))}</h1>
            <div className='flex justify-center'><div className="loader max-sm:w-30px max-sm:border-4"></div></div>
        </div>
    )
}
