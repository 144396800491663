import { Link } from 'react-router-dom';
import { FaLink, FaSearch } from 'react-icons/fa';
import Patient from '../Components/Patient';
import React, { useState,useCallback, useEffect } from 'react';
import Loading from '../Components/Loading';
/* import data from '../data.json'; */
import NotFoundUserPage from './NotFoundUserPage';
import { useDispatch, useSelector } from 'react-redux';
import api from '../config';
import LoadingPage from '../Components/LoadingPage';

export default function Patients() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token); // Assuming 'auth' is your authentication slice
  const [searchTerm, setSearchTerm] = useState('');

  const [loading, setLoading] = useState(true);

  const [data,setData]=useState(null)
  const [newData,setNewData]=useState(null)
  const [counter,setCounter]=useState(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('patients/all', {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Handle the response as needed
        setData(response.data)
        setCounter(response.data.length)
      } catch (error) {
        // Handle errors
       
      }finally {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, [dispatch, token]); 
  

 
  const handleSearch = useCallback(() => {
    if (!data) return; // Ensure data is available
  
    if (searchTerm.trim() !== '') {
      const filteredData = data.filter(patient => {
        const patientName = patient.firstName.toLowerCase();
        return patientName.includes(searchTerm.toLowerCase());
      });
      setCounter(filteredData.length)
      setNewData(filteredData);
    } else {
      setNewData(null); // Clear newData if search term is empty
      setCounter(data.length)
    }
  }, [searchTerm, data]);

  


  return (
    <div>
      {
        loading ? <LoadingPage />:
    <div className={`flex mx-2 flex-col patients`}>
      <div className={`flex items-center justify-between gap-1 mx-3 p-2 url my-2 max-sm:mx-0 max-sm:p-1 max-sm:flex-col`}>
        <div className={`max-sm:order-1 flex items-center gap-1 mx-3 p-2 my-2 max-sm:mx-0 max-sm:p-1`}>
          <FaLink className={`icon`} />
          <Link to="/add-patient" className={`p-3 max-sm:text-sm`}>Ajouter un nouvel Patient</Link>
        </div>
        <div className='max-sm:order-3 bg-white counter max-sm:w-full max-sm:flex max-sm:justify-center max-sm:items-center my-2 '>
          {data?.length > 1 ? <span className=' w-40% max-sm:text-exsm active-color'>{counter} Patients Trouvés
          </span> : <span className=' w-40% max-sm:text-exsm active-color'>{counter} Patient Trouvé
          </span>}
        </div>
        <div className={`w-60% flex items-center gap-2 max-sm:w-full max-sm:my-2 max-sm:order-2`}>

          <input
            type="text"
            placeholder={`Chercher...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className=' max-sm:text-exsm'
          />
          <button onClick={handleSearch} className='max-sm:text-exsm bg-white active-color'><FaSearch/></button>
        </div>
        <div>

        </div>
      </div>
      {
      (newData || data)  && (
        <div className={`flex flex-wrap`}>
          {(newData || data).map((patient, index) => (
            <Patient key={index} patient={patient} />
          ))}
          <div>{loading && <Loading />}</div>
        </div>
      ) 
    }
    {
      counter <= 0 && <NotFoundUserPage message="Aucun Patient Trouvé" />
    }


    </div>
}
</div>
  );
}
