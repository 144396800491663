// consultationProfile.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'

import { motion } from "framer-motion"
import { FaX } from 'react-icons/fa6';
import { FaPlusSquare, FaDownload } from 'react-icons/fa';

import { Link,useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import api from '../config';
import { IoMdDoneAll } from 'react-icons/io';
import LoadingPage from '../Components/LoadingPage';





export default function ConsultationPage({setShowBackDRropAlertMessage, setValueOfBackdrop, setValueOfUrl }) {
  const { id ,terminated} = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token); // Assuming 'auth' is your authentication slice
  const [patient, setPatient] = useState(null);
  const [consultation, setConsultation] = useState(null);
  const [editedConsultation, setEditedConsultation] = useState(null);
  const [isForm1Changed, setIsForm1chisChanged] = useState(false);
  const [isSubmittingForm1, setIsSubmittingForm1] = useState(false);
  const [isForm2Changed, setIsForm2chisChanged] = useState(false);
  const [isSubmittingForm2, setIsSubmittingForm2] = useState(false);
  const navigate=useNavigate()



  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      try {
        
        const consultationResponse = await api.get(`consultations/${id}`, {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Handle the response as needed
        try {
          const patientResponse = await api.get(`patients/${consultationResponse.data.patientId}`, {
            headers: {
              token: `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          setPatient(patientResponse.data)
        }catch{
          setPatient(null)
        }
        setConsultation(consultationResponse.data)
        
      } catch (error) {
        navigate("/consultations")
      } finally {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, [dispatch, token, navigate]);


 



  const handleInputChangeForm1 = (e) => {
    setIsForm1chisChanged(true)
    const { name, value } = e.target;
    setEditedConsultation((prevconsultation) => ({
      ...prevconsultation,
      [name]: value,
    }));
  };
  const handleInputChangeForm2 = (e) => {
    setIsForm2chisChanged(true)
    const { name, value } = e.target;
    setEditedConsultation((prevconsultation) => ({
      ...prevconsultation,
      [name]: value,
    }));
  };

  const handleSubmit = async (e,target) => {
    e.preventDefault();


    try {
       if (target===2){
        setIsSubmittingForm1(true);
      }else if (target===3){
        setIsSubmittingForm2(true);
      }
      

      await api.put(`consultations/${id}`, editedConsultation, {
        headers: {
          token: `Bearer ${token}`, // Correct the header key to 'Authorization'
        },
      });
   


      // Simulate a delay (you can replace this with an actual API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));

     

      // You can update the global state or perform other actions as needed

      // Simulate a delay (you can replace this with an actual API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Update the local state with the edited consultation data
      setConsultation(editedConsultation);

      // You can update the global state or perform other actions as needed
    } catch (error) {
      console.error('Error updating consultation data:', error);
    } 
    
    
      setIsSubmittingForm1(false);
      setIsForm1chisChanged(false)
      setIsSubmittingForm2(false);
      setIsForm2chisChanged(false)
    
  };


  const handellDelete = (rowData) => {
    console.log(consultation.firstName + " " + consultation.lastName)
    setShowBackDRropAlertMessage(true);
    setValueOfUrl(`consultations/${consultation._id}`)
    setValueOfBackdrop(consultation.firstName + " " + consultation.lastName)
    
    
  };

  const handlePrint = () => {
   navigate("/consultation-basic-page/"+id)
  };
  function calculateAge(dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age based on the month and day
    if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
  const formattedDate = (dateOfBirth) => {

    return new Date(dateOfBirth).toISOString().split('T')[0]

  };

  const handellConfirme = async() => {
  await api.put(`consultations/confirm/${consultation._id}`,{isTerminated:true} ,{
      headers: {
        token: `Bearer ${token}`, // Include the token in the Authorization header
      },
    });
    navigate("/")
  }
  return (
    <div>
      {
       loading ? <LoadingPage />:
    <div className={`flex mx-2 flex-col consultations`} >
      {
        consultation ? <div className={`flex flex-wrap flex-col`}>
          <div className={` url flex items-center justify-between gap-1 mx-3 p-2 my-2 max-sm:mx-0 max-sm:p-1`}>
            <div className='flex items-center'>
              <FaPlusSquare className={`icon max-sm:text-exsm`} />
              <Link to={"/add-consultation/"+consultation.patientId} className={`p-3 max-sm:text-exsm`}>Ajouter</Link>
            </div>
            <div className="flex items-center color-error text-xl max-sm:text-exsm">

              <motion.button transition={{ duration: 0.1 }}
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray"
                }}><FaDownload onClick={handlePrint} />
              </motion.button>
              <span className='text-sm mx-2  max-sm:text-exsm'>
                Téléchargez</span>

            </div>
            {
              terminated && <div className="flex items-center color-error text-xl max-sm:text-exsm">

              <motion.button transition={{ duration: 0.1 }}
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray"
                }}><IoMdDoneAll onClick={handellConfirme} />
              </motion.button>
              <span className='text-sm mx-2  max-sm:text-exsm'>
              Terminer cette consultation</span>

            </div>
            }
            <div className="flex items-center mx-2  color-error text-xl max-sm:text-sm">

              <motion.button transition={{ duration: 0.1 }}
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray"
                }}><FaX onClick={handellDelete}></FaX>
              </motion.button>
              <span className='text-sm mx-2 max-sm:text-exsm'>Remove</span>
            </div>
          </div>
          <div className='flex flex-wrap'>
            <div className="flex-1 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
             

             {
              patient ? <div>
                 <h1 className='w-full p-4 text-xl max-sm:text-sm'>  Detail De Patient</h1>
              <img className='w-100px max-sm:w-50px max-sm:text-exsm active-color mt-10 max-sm:mt-3' src={patient.img} alt={`Person ${patient.firstname}`} />
             <form className="row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1" onSubmit={handleSubmit}>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="firstname" className='flex-1 '>
                   Nom
                 </label>
                 <input
                   type="text"
                   id="firstname"
                   name="firstName"
                   defaultValue={patient.firstName}
 
                   readOnly
                 />
               </div>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="lastname" className='flex-1'>
                   Prénom
                 </label>
                 <input
                   type="text"
                   id="lastname"
                   name="lastName"
                   defaultValue={patient.lastName}
                   readOnly
                 />
               </div>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="age" className='flex-1'>
                   Date de naissance
                 </label>
                 <input
                   type="date"
                   id="age"
                   name='dateOfBirth'
                   defaultValue={formattedDate(patient?.dateOfBirth)}
                   readOnly
 
                 />
               </div>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="age" className='flex-1'>
                   Carte d'identité nationale
                 </label>
                 <input
                   type="text"
                   id="age"
                   name='cin'
                   defaultValue={patient.cin}
                   readOnly
 
                 />
               </div>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="age" className='flex-1'>
                   Âge
                 </label>
                 <input
                   type="text"
                   defaultValue={calculateAge(patient?.dateOfBirth)}
 
                   readOnly
                 />
               </div>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="gender" className='flex-1'>
                   Situation Familiale
                 </label>
                 <input type="text" defaultValue={patient.gender} readOnly />
               </div>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="address" className='flex-1'>
                   Adresse
                 </label>
                 <input
                   type="text"
                   id="address"
                   name="address"
                   defaultValue={patient.address}
                   readOnly
                 />
               </div>
               <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                 <label htmlFor="phone" className='flex-1'>
                   Téléphone
                 </label>
                 <input
                   type="text"
                   id="phone"
                   name="phone"
                   defaultValue={patient.phone}
                   readOnly
                 />
               </div>
              
 
 
             </form>
              </div> : <h1>Le patient a été supprimé</h1>
             }
            </div>
            <div className="flex-1 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
              <h1 className='w-full p-4 text-xl max-sm:text-sm'> Détails de Santé</h1>
              <img className='w-100px max-sm:w-50px mt-10 max-sm:mt-3' src="/img/doctor-visit.png" alt={`Person ${consultation._id}`} />
              <form onSubmit={(e) => handleSubmit(e, 2)} className="row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1" >
                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                  <label htmlFor="weight" className='flex-1'>
                    Le poids
                  </label>
                  <input
                    type="text"
                    id="weight"
                    name="weight"
                    defaultValue={consultation.weight}
                    onChange={handleInputChangeForm1}
                  />
                </div>

                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                  <label htmlFor="bdc" className='flex-1'>
                    BDC
                  </label>
                  <input
                    type="text"
                    id="bdc"
                    name="bdc"
                    defaultValue={consultation.bdc}
                    onChange={handleInputChangeForm1}
                  />
                </div>

                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                  <label htmlFor="bloodPressure" className='flex-1'>
                    La tension artérielle
                  </label>
                  <input
                    type="text"
                    id="bloodPressure"
                    name="bloodPressure"
                    defaultValue={consultation.bloodPressure || ''}
                    onChange={handleInputChangeForm1}
                  />
                </div>

                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                  <label htmlFor="bloodSugar" className='flex-1'>
                    La glycémie
                  </label>
                  <input
                    type="text"
                    id="bloodSugar"
                    name="bloodSugar"
                    defaultValue={consultation.bloodSugar}
                    onChange={handleInputChangeForm1}
                  />
                </div>

                  

                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                  <label htmlFor="price" className='flex-1'>
                    Paiement
                  </label>
                  <input
                    type="text"
                    id="price"
                    name="price"
                    defaultValue={consultation.price}
                    onChange={handleInputChangeForm1}
                  />
                </div>

                {
                  isForm1Changed&& (
                    isSubmittingForm1 ? (
                      <div className="loader max-sm:w-30px max-sm:border-4"></div>
                    ) : (
                      <motion.button
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        transition={{ duration: 0.2 }}
                        whileHover={{
                          scale: 1.1,
                          textShadow: "0px 0px 4px gray"
                        }}
                        type="submit"
                        className="bg-blue-500 text-white p-2 rounded my-4 max-sm:text-exsm"
                        disabled={isSubmittingForm1}
                      >
                        Sauvegarder
                      </motion.button>
                    )
                  )
                }
              </form>
            </div>
          </div>
          <div className='flex flex-wrap'>
            <div className="flex-1 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
              <h1 className='w-full p-4 text-xl max-sm:text-sm'>Consultant's Report</h1>

              <img className='w-100px max-sm:w-50px mt-10 max-sm:mt-3' src="/img/medical-history.png" alt={`Person ${consultation.id}`} />
              <form className="row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1" onSubmit={(e) =>handleSubmit(e, 3)}>
                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
                  <label htmlFor="observations" className='flex-1 '>
                    Constatations
                  </label>
                  <textarea
                    type="text"
                    id="observations"
                    name="observations"
                    defaultValue={consultation.observations}
                    onChange={handleInputChangeForm2}
                    rows={6}
                  />
                </div>

                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
                  <label htmlFor="recommendations" className='flex-1'>
                    Recommandations
                  </label>
                  <textarea
                    type="text"
                    id="recommendations"
                    name="recommendations"
                    defaultValue={consultation.recommendations}
                    onChange={handleInputChangeForm2}
                    rows={6}
                  />
                </div>

                <h1 className='w-full p-3 text-xl max-sm:text-sm my-4'>Les Analyses</h1>

                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                  <label htmlFor="analysis" className='flex-1'>
                    L'analyse
                  </label>
                  <input
                    type="text"
                    id="analysis"
                    name="analysis"
                    defaultValue={consultation.analysis}
                    onChange={handleInputChangeForm2}
                  />
                </div>

                <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
                  <label htmlFor="analysisReport" className='flex-1'>
                    Rapport de l'analyse
                  </label>
                  <textarea
                    type="text"
                    id="analysisReport"
                    name="analysisReport"
                    defaultValue={consultation.analysisReport}
                    onChange={handleInputChangeForm2}
                    rows={6}
                  />
                </div>



                {
                  isForm2Changed&& (
                    isSubmittingForm2 ? (
                      <div className="loader max-sm:w-30px max-sm:border-4"></div>
                    ) : (
                      <motion.button
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        transition={{ duration: 0.2 }}
                        whileHover={{
                          scale: 1.1,
                          textShadow: "0px 0px 4px gray"
                        }}
                        type="submit"
                        className="bg-blue-500 text-white p-2 rounded my-4 max-sm:text-exsm"
                        disabled={isSubmittingForm2}
                      >
                        Sauvegarder
                      </motion.button>
                    )
                  )
                }
              </form>
            </div>

          </div>
        </div> :
          <div className="flex-1 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
            <h1>Il n'y a aucun consultation trouvé</h1>

          </div>
      }
    </div>
} </div>
  );
}