
import './index.css'; // You can create this file for your styles


// react router import !!
import React, { useEffect, useState } from 'react';

import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import RouteParm from './RouteParm';
import Home from './Routes/Home';
import Profile from './Routes/Profile';
import BackDropAlertMessage from './Components/BackDropAlertMessage';
import AddNurse from './Routes/AddNurse';
import Login from './Routes/Login';
import NotFound from './Routes/NotFound';
import Patients from './Routes/Patients';
import AddPatient from './Routes/AddPatient';
import PatientProfile from './Routes/PatientProfile';
import Consultations from './Routes/Consultations';
import ConsultationPage from './Routes/ConsultationPage';
import BasicPage from './Routes/BasicPage';
import AddConsultation from './Routes/AddConsultation';
import Appointments from './Routes/Appointments';
import AddAppointment from './Routes/AddAppointment';
import TodConsultations from './Routes/TodConsultations';
import Money from './Routes/Money';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { useDispatch, useSelector } from 'react-redux';
import { tokenExpired } from './store/Auth/authActions';



// react function strart 
const App = () => {
  const [showBackDRropAlertMessage, setShowBackDRropAlertMessage] = useState(false);
  const [valueOfBackdrop, setValueOfBackdrop] = useState('')
  const [valueOfUrl, setValueOfUrl] = useState('')
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const token = useSelector(state => state.auth.token);
  const expirationTime = useSelector(state => state.auth.expirationTime);
  useEffect(() => {
    if (!token || !expirationTime || isTokenExpired(parseInt(expirationTime))) {
      dispatch(tokenExpired());
    }
  }, [dispatch, isAuthenticated,token,expirationTime]);

  const isTokenExpired = (exp) => {
    const currentTime = Date.now() / 1000; // Convert to seconds
    
    return parseInt(currentTime) > exp;
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        {/* Main Routes */}
        <Route
          path='/'
          element={<RouteParm />}
        >
          <Route index element={<Home showBackDRropAlertMessage={showBackDRropAlertMessage}
            setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl} />} />
          <Route path='profile' element={<Profile />} />
          <Route path='add-nurse' element={<AddNurse />} />
          <Route path='patient' element={<Patients />} />
          <Route path='consultations' element={<Consultations />} />
          <Route path='consultations/today' element={<TodConsultations/>} />
          <Route path='add-patient' element={<AddPatient />} />
          <Route path='patient-profile/:id' element={<PatientProfile setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl}/>} />
          <Route path='consultation/:id' element={<ConsultationPage setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl}/>} />
          <Route path='consultation/:id/:terminated' element={<ConsultationPage setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl}/>} />
          <Route path='add-consultation/:id' element={<AddConsultation />} />
          <Route path='add-consultation' element={<AddConsultation />} />
          <Route path='add-appointment' element={<AddAppointment />} />
          <Route path='/appointments' element={<Appointments setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl} />} />
          <Route path='/money' element={<Money />} />

        </Route>
  
        {/* Separate Login Route */}
        <Route path='consultation-basic-page/:id' element={<BasicPage />} />
        <Route path='login' element={<Login />} />
        <Route path='*' element={<NotFound />}/>
      </React.Fragment>
    )
  );
  

  return (
    <div className="App">
      {showBackDRropAlertMessage && <BackDropAlertMessage valueOfUrl = {valueOfUrl} valueOfBackdrop={valueOfBackdrop} setShowBackDRropAlertMessage={setShowBackDRropAlertMessage}  />}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
