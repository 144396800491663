const initialNotificationCounter = localStorage.getItem('notificationCounter');

const initialState = {
    notificationCounter:initialNotificationCounter || 0,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATION_COUNTER':
        localStorage.setItem('notificationCounter', action.payload);
        return {
            ...state,
            notificationCounter: action.payload
        };
        case 'RESET_NOTIFICATION_COUNTER':
            localStorage.setItem('notificationCounter', 0);
            return {
                ...state,
                notificationCounter: 0
            };
        default:
            return state;
    }
  };
  
  export default reducer;
  