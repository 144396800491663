
  export const setNotificationCounter = (counter) => ({
    type: 'SET_NOTIFICATION_COUNTER',
    payload: counter
  });

  export const resetNotificationCounter = () => {
    localStorage.setItem('notificationCounter', 0);
    return {
      type: 'RESET_NOTIFICATION_COUNTER',
      payload: 0
    };
  };