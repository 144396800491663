import React, { useEffect, useState } from 'react'
import { FaBedPulse } from "react-icons/fa6";
import { FaCalendar, FaCommentMedical, FaFileMedicalAlt } from "react-icons/fa";
import Chart from '../Components/Chart';
import Table from '../Components/Table';
import ActualPatient from '../Components/ActualPatient';
import NextPatient from '../Components/NextPatient';




import api from '../config';
import { useSelector } from 'react-redux';
import LoadingPage from '../Components/LoadingPage';
const generateRandomData = () => {
  const data = [];
  for (let i = 0; i < 12; i++) {
    data.push(Math.floor(Math.random() * 301)); // Generate random value between 0 and 300
  }
  return data;
};



let socket
export default function Home({ setShowBackDRropAlertMessage, showBackDRropAlertMessage, setValueOfBackdrop,setValueOfUrl }) {
  document.title = '🏠 Home';
  const [appointment, setAppointment] = useState(null);
  const [consultation, setConsultation] = useState(null);
  const [patient, setPatient] = useState(null);
  const [money, setMoney] = useState(null);
  const [actConsultation, setActConsultation] = useState(null);
  const [nextConsultation, setNextConsultation] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.token); // Assuming 'auth' is your authentication slice

useEffect(() => {

  const fetchData = async () => {
    try {
     
      const consultationResponse = await api.get(`consultations/current`, {
        headers: {
          token: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      const patientResponse = await api.get('patients/all', {
        headers: {
          token: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      const response1 = await api.get(`appointments/all`, {
        headers: {
          token: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      const response2 = await api.get(`consultations/money`, {
        headers: {
          token: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      setMoney(response2.data)
      // Handle the response as needed
     
      setAppointment(response1.data)
      // Handle the response as needed
      setPatient(patientResponse.data)
      const response = await api.get(`consultations/all`, {
        headers: {
          token: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });

      // Handle the response as needed
      
     
      setConsultation(response.data)

      if (consultationResponse.data.length>=2){
        setActConsultation(consultationResponse.data[0])
        setNextConsultation(consultationResponse.data[1])
      }
      else  if (0<consultationResponse.data.length<=1){
        setActConsultation(consultationResponse.data[0])
      }
    } catch (error) {
     
    }finally {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    }
  };

  fetchData(); // Invoke the fetchData function
}, [token])

const formattedDate = (dateOfBirth) => {

  return new Date(dateOfBirth).toISOString().split('T')[0]

};

const filteredData = consultation?.map(({ _id, updatedAt, price, firstName, lastName }) => ({
  _id,
  price,
  updatedAt: formattedDate(updatedAt), // Corrected usage of formattedDate function
  firstName,
  lastName,
}));

const filteredPatient = patient?.map(({ _id, createdAt, firstName, lastName }) => ({
  _id,
  createdAt: formattedDate(createdAt), // Corrected usage of formattedDate function
  firstName,
  lastName,
}));

const filteredAppointment = appointment?.map(({ _id, date, firstName, lastName,reason }) => ({
  _id,
  date: formattedDate(date), // Corrected usage of formattedDate function
  firstName,
  lastName,
  reason
}));


  return (
    <div>
      {
        loading ? <LoadingPage />:<div className='home mt-4'>
        <div className="mx-2 flex flex-wrap p-4">
          <div className="h-full w-full max-sm:w-1/2 p-1 lg:w-1/3 md:w-2/4 sm:w-2/4 rounded-3xl bg-transparent row  ">
            <div className=" bg-red-500 bg-opacity-20  px-6 py-4 relative backdrop-blur-md rounded-xl ">
              <FaBedPulse className='home-icon text-exl text-md max-sm:text-sm' />
  
              <div className="title-group flex flex-col justify-around items-center ml-7 ">
                <h1 className=" title-font sm:text-2xl font-extrabold text-black  text-3xl  mb-3 max-sm:text-sm">Les Patients</h1>
                <p className="mb-3 text-black max-sm:text-exsm">{patient?.length} Patients</p>
              </div>
            </div>
          </div>
          <div className="h-full w-full max-sm:w-1/2 p-1 lg:w-1/3 md:w-2/4 sm:w-2/4 rounded-3xl bg-transparent row">
            <div className=" px-6 py-4 relative backdrop-blur-md bg-opacity-20 bg-green-500 rounded-xl">
              <FaCalendar className='home-icon text-exl max-sm:text-sm' />
              <div className="title-group flex flex-col justify-around items-center ml-7">
                <h1 className="title-font sm:text-2xl font-bold text-black  text-3xl mb-3 max-sm:text-sm">Les rendez-vous</h1>
                <p className="mb-3 text-black max-sm:text-exsm">{appointment?.length} Rendez-vous</p>
              </div>
            </div>
          </div>
         
          <div className="h-full w-full  p-1 lg:w-1/3 md:w-full rounded-3xl bg-transparent row">
            <div className="px-6 py-4 relative backdrop-blur-md bg-opacity-20 bg-blue-500 rounded-xl">
  
              <FaCommentMedical className='home-icon text-exl max-sm:text-sm' />
              <div className="title-group flex flex-col justify-around items-center ml-7">
                <h1 className="title-font sm:text-2xl font-bold text-black  text-3xl  mb-3 max-sm:text-sm">Les Consultations</h1>
                <p className="mb-3 text-black max-sm:text-exsm">{consultation?.length}  Consultations</p>
              </div>
            </div>
          </div>
          
        </div>
        <div className='flex mt-10 grow flex-nowrap md:flex-wrap max-sm:flex-wrap sm:flex-wrap max-sm:m-1 mx-2 '>
        {
          actConsultation ? <ActualPatient consultation={actConsultation} />: <div className="max-sm:text-exsm flex-1 flex-col nextPatient patient-home  ml-3 mr-3  flex  backdrop-blur-sm p-4  items-center justify-center  max-sm:min-w-full max-sm:m-0 max-sm:mt-3"> Il y a aucun patient pour le moment.</div>
        }
        {
          nextConsultation ? <NextPatient consultation={nextConsultation}/> :<div className="max-sm:text-exsm flex-1 flex-col nextPatient patient-home  ml-3 mr-3  flex  backdrop-blur-sm p-4  items-center justify-center  max-sm:min-w-full max-sm:m-0 max-sm:mt-3">Il y a aucun patient pour le moment.</div>
        }
       
        </div>
        
        {
         consultation&& <Table isView={true} viewUrl="/consultation/" addUrl={"/add-consultation/"} url="consultations/" action={true} counterName="consultation" title='Les Consultations' data={filteredData} setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl} />
        }  
        {
          patient && <Table viewUrl="/patient-profile/" addUrl={"/add-patient/"} isView={true} action={true} url="/patient-profile/"   title='Les Patients' counterName="patient" data={filteredPatient} setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop}  />

        }  
        {
          appointment && <Table addUrl={"/add-appointment/"}  action={true} title='Les Rendez-Vous' counterName="Rendez-Vous" data={filteredAppointment} setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} />
        }  
        <Chart data={generateRandomData} title='Les Patients' />
        <Chart data={generateRandomData} title='Les Consultations' />
      </div>
      }
    </div>


  )
}
