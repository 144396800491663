// consultationProfile.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import { AnimatePresence, motion } from "framer-motion"




import { useSelector } from 'react-redux';
import api from '../config';





export default function AddConsultation() {
  const { id } = useParams();
  const navigate=useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const [inputValues, setInputValues] = useState({
    height:'',
    weight: '',
    bdc: '',
    bloodPressure: '',
    bloodSugar: '',
    price: '',
    observations: '',
    recommendations: '',
    analysis: '',
    analysisReport: '',
    patientId: "",
    firstName:'',
    lastName:''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const [patient, setPatient] = useState(null);
  const [allPatient, setAllPatient] = useState(null);
  const fetchData = async () => {
    try {
      if (id) {
        const response = await api.get(`patients/${id}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        });
        setPatient(response.data);
        setFilteredData([response.data]); // Ensure filteredData contains the single patient
      } else {
        const responseAll = await api.get(`patients/all`, {
          headers: {
            token: `Bearer ${token}`,
          },
        });
        setAllPatient(responseAll.data);
        setFilteredData(responseAll.data); // Set filteredData initially to all patients
      }
    } catch (error) {
      // Handle errors
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, token]);
  useEffect(() => {
    // Filter data based on the search term
    const filtered = allPatient?.filter((patient) => {
      const fullName = `${patient.firstName} ${patient.lastName}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredData(filtered);
  }, [ searchTerm,allPatient]);

  

  useEffect(()=>{
    if (!patient){
      setErrors(true)
    }
    else{
      setErrors(false)
    }
 
  },[patient])

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Initialize errors as an empty array
   
    
    
    
    
    // Update the errors state with the new array of errors

   
  
    // Check if there are any errors
    if(!errors) {
      try {
        setIsSubmitting(true);
        // Create a new user object with input values
        const newUser = {
          height: inputValues.height,
          weight: inputValues.weight,
          bdc: inputValues.bdc,
          bloodPressure: inputValues.bloodPressure,
          bloodSugar: inputValues.bloodSugar,
          price: inputValues.price,
          observations: inputValues.observations,
          recommendations: inputValues.recommendations,
          analysis: inputValues.analysis,
          analysisReport: inputValues.analysisReport,
          patientId: patient._id,
          firstName:patient.firstName,
          lastName:patient.lastName
        };
  
        console.log(newUser)
        await api.post('consultations/create', newUser, {
          headers: {
            token: `Bearer ${token}`, // Correct the header key to 'Authorization'
          },
        });
  
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(`/patient-profile/${id ? id : newUser.patientId}`);
      } catch  {
      }
    }
    setIsSubmitting(false);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    
  };
  const handleSelectChange = (e) => {
    setErrors(false)
    const selectedPatient = filteredData.find((patient) => {
      const fullName = `${patient.firstName} ${patient.lastName}`;
      return fullName.toLowerCase() === e.target.value.toLowerCase();
    });
    setPatient(selectedPatient);
  };
  
  const formattedDate = (dateString) => {
    if (!dateString) {
      return ''; // or any default value you prefer
    }
  
    // Parse the date string to a JavaScript Date object
    const date = new Date(dateString);
  
    // Check if the parsed date is valid
    if (isNaN(date.getTime())) {
      return ''; // or handle invalid date as needed
    }
  
    // Use the toISOString() method to get the date in "yyyy-MM-dd" format
    return date.toISOString().split('T')[0];
  };
  
  
  return (
    <div className={`flex mx-2 flex-col consultations`} >
      {
        !id &&<div className="flex header flex-wrap flex-col backdrop-blur-sm addConsultation w-full p-4 my-3">

        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
          <label htmlFor="" className='flex-1'>Trouver un patient</label>
          <input
            type="text"
            placeholder="Search for a patient"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
          <label htmlFor="" className='flex-1'>Sélectionnez un patient</label>

          <select onChange={handleSelectChange}>
            <option value="">Select a patient</option>
            {filteredData?.map((patient,i) => {
              const fullName = `${patient.firstName} ${patient.lastName}`;
              return (
                <option key={i} value={fullName}>
                  {fullName}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      }
      <form onSubmit={handleSubmit} className="flex flex-wrap flex-col backdrop-blur-sm addConsultation w-full p-4" >
        <div className={`flex flex-wrap`}>
          {
            patient && <div className="flex-1 flex-col border-2 rounded-md border-blue-300  mx-3 my-2 flex  p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
              <h1 className='w-full p-4 text-xl max-sm:text-sm'>  Detail De Patient</h1>
              <img className='w-100px max-sm:w-50px mt-10 max-sm:mt-3' src={patient.img} alt={`Person `} />
              <div className='row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1'>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="firstname" className='flex-1 '>
                  Nom
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  defaultValue={patient.firstName}
                  readOnly
                 
                />
              </div>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="lastname" className='flex-1'>
                  Prénom
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  defaultValue={patient.lastName}
                  readOnly
                />
              </div>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="age" className='flex-1'>
                Date de naissance
                </label>
                <input
                  type="date"
                  id="age"
                  name='dateOfBirth'
                  defaultValue={formattedDate(patient.dateOfBirth)}
                  readOnly
                  
                />
              </div>
              
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="gender" className='flex-1'>
                  Sexe
                </label>
                <input
                  type="text"
                  id="gender"
                  name="gender"
                  defaultValue={patient.gender}
                  readOnly
                />
              </div>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="address" className='flex-1'>
                  Adresse
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  defaultValue={patient.address}
                  readOnly
                />
              </div>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="phone" className='flex-1'>
                  Téléphone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  defaultValue={patient.phone}
                  readOnly
                />
              </div>
              </div>
            </div>

          }
          <div className="flex-1 flex-col border-2 rounded-md border-blue-300 mx-3 my-2 flex  p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
            <h1 className='w-full p-4 text-xl max-sm:text-sm'> Détails de Santé</h1>
            <img className='w-100px max-sm:w-50px mt-10 max-sm:mt-3' src="/img/doctor-visit.png" alt={`Person`} />
            <div className='row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1'>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="weight" className='flex-1'>
                  Le poids
                </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="weight"
                  name="weight"


                />
              </div>

              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="bdc" className='flex-1'>
                  BDC
                </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="bdc"
                  name="bdc"

                />
              </div>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="bdc" className='flex-1'>
                La taille
                </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="bdc"
                  name="height"

                />
              </div>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="bloodPressure" className='flex-1'>
                  La tension artérielle
                </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="bloodPressure"
                  name="bloodPressure"


                />
              </div>

              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="bloodSugar" className='flex-1'>
                  La glycémie
                </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="bloodSugar"
                  name="bloodSugar"


                />
              </div>


              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="price" className='flex-1'>
                  Paiement
                </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="price"
                  name="price"


                />
              </div>
            </div>
          </div>
        </div>
        <div className={`flex flex-wrap`}>
          <div className="flex-1 flex-col border-2 rounded-md border-blue-300 mx-3 my-2 flex  p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
            <h1 className='w-full p-4 text-xl max-sm:text-sm'>Consultant's Report</h1>

            <img className='w-100px max-sm:w-50px mt-10 max-sm:mt-3' src="/img/medical-history.png" alt="" />
            <div className='row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1'>
              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
                <label htmlFor="observations" className='flex-1 '>
                  Constatations
                </label>
                <textarea
                  onChange={handleInputChange}
                  type="text"
                  id="observations"
                  name="observations"


                  rows={6}
                />
              </div>

              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
                <label htmlFor="recommendations" className='flex-1'>
                  Recommandations
                </label>
                <textarea
                  onChange={handleInputChange}
                  type="text"
                  id="recommendations"
                  name="recommendations"

                  rows={6}
                />
              </div>

              <h1 className='w-full p-3 text-xl max-sm:text-sm my-4'>Les Analyses</h1>

              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                <label htmlFor="analysis" className='flex-1'>
                  L'analyse
                </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="analysis"
                  name="analysis"


                />
              </div>

              <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
                <label htmlFor="analysisReport" className='flex-1'>
                  Rapport de l'analyse
                </label>
                <textarea
                  onChange={handleInputChange}
                  type="text"
                  id="analysisReport"
                  name="analysisReport"


                  rows={6}
                />
              </div>

            </div>
          </div>
        </div>
        {
          isSubmitting ?
            <div className='flex justify-center'><div className="loader max-sm:w-30px max-sm:border-4"></div></div>
            :
            <div className='flex justify-center'>
              <motion.button
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                transition={{ duration: 0.2 }}
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray"
                }}
                type="submit"
                className="bg-blue-500 text-white p-2 rounded my-4 max-sm:text-exsm w-30% "
                disabled={isSubmitting}
              >
                Ajouter
              </motion.button>
            </div>

        }
        <AnimatePresence>
          {errors &&
            <motion.div
              className="errorBox p-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
              exit={{ opacity: 0, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
            >
              <h1>
                Veuillez sélectionner un patient d'abord.
              </h1>
            </motion.div>
          }
        </AnimatePresence>
      </form>


    </div>




  );
}