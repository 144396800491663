import { Link, useNavigate } from 'react-router-dom';
import { FaLink, FaSearch } from 'react-icons/fa';

import React, { useCallback, useEffect, useState } from 'react';
import Loading from '../Components/Loading';

import NotFoundUserPage from './NotFoundUserPage';

import Appointment from '../Components/Appointment';
import { useSelector } from 'react-redux';
import api from '../config';
import LoadingPage from '../Components/LoadingPage';

export default function Appointments({setShowBackDRropAlertMessage, setValueOfBackdrop, setValueOfUrl}) {
  const [appointment, setAppointment] = useState(null);
  const [newAppointment, setNewAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [counter,setCounter]=useState(null)
  const token = useSelector((state) => state.auth.token); 
  const navigate=useNavigate()
 


  

  useEffect(() => {

    const fetchData = async () => {
      try {
        
        const response = await api.get(`appointments/all`, {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Handle the response as needed
       
        setAppointment(response.data)
        setCounter(response.data.length)
       
      } catch (error) {
        navigate("/");
      }
      finally {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, [token, navigate]);
 
  


  const handleSearch = useCallback(() => {
    if (!appointment) return; // Ensure data is available
  
    if (searchTerm.trim() !== '') {
     
      const filteredData =appointment?.filter((appointment) =>{
     return appointment.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    appointment.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    appointment.date.toLowerCase().includes(searchTerm.toLowerCase())
      });
      setCounter(filteredData.length)
      setNewAppointment(filteredData);
    } else {
      setNewAppointment(null); // Clear newData if search term is empty
      setCounter(appointment.length)
    }
  }, [searchTerm, appointment]);

 // ...

return (
  <div>
      {
       loading ? <LoadingPage />:
  <div className={`flex mx-2 flex-col appointments`}>
    <div className={`flex items-center justify-between gap-1 mx-3 p-2 url my-2 max-sm:mx-0 max-sm:p-1 max-sm:flex-col`}>
      <div className={`max-sm:order-1 flex items-center gap-1 mx-3 p-2 my-2 max-sm:mx-0 max-sm:p-1`}>
        <FaLink className={`icon`} />
        <Link to="/add-appointment" className={`p-3 max-sm:text-sm`}>Ajouter un rendez-vous</Link>
      </div>
      <div className='max-sm:order-3 bg-white counter max-sm:w-full max-sm:flex max-sm:justify-center max-sm:items-center my-2 '>
        {appointment?.length > 1 ? <span className=' w-40% max-sm:text-exsm active-color'>{counter} appointments Trouvés
        </span> : <span className=' w-40% max-sm:text-exsm active-color'>{counter} appointment Trouvé
        </span>}
      </div>
      <div className={`w-50% flex items-center gap-2 max-sm:w-full max-sm:my-2 max-sm:order-2`}>

        <input
          type="text"
          placeholder={`Chercher...`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className=' max-sm:text-exsm'
        />
         <button onClick={handleSearch} className='max-sm:text-exsm bg-white active-color'><FaSearch/></button>
      </div>
      <div></div>
    </div>
    {
      (newAppointment|| appointment) &&(
        <div className={`flex flex-wrap`}>
          {(newAppointment|| appointment).map((appointment, index) => (
            <Appointment key={index} appointment={appointment} setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl} />
          ))}
          <div>
            {loading && appointment > 4 && <Loading />}
          </div>
        </div>
      ) 
     
    }
    {
        counter <= 0 && <NotFoundUserPage message="Aucun Appointment Trouvé" />
      }
  </div>
}
</div>
);

}
