// PatientProfile.js

import React, {  useState } from 'react';



import { useSelector } from 'react-redux';




export default function Profile() {
  const  user  = useSelector((state) => state.auth.user); // Assuming 'auth' is your authentication slice

  const [patient] = useState(user);
  const [editedPatient] = useState(user);
  

  /* const handleInputChange = (e) => {
    setIschisChanged(true)
    const { name, defaultValue } = e.target;
    setEditedPatient((prevPatient) => ({
      ...prevPatient,
      [name]: defaultValue,
    }));
  };
 */
  /* const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      setIsSubmitting(true);

      // Update the data.json file with the edited patient data
     

      // Simulate a delay (you can replace this with an actual API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Update the local state with the edited patient data
      setPatient(editedPatient);

      // You can update the global state or perform other actions as needed

    } catch (error) {
     
    } finally {
      setIsSubmitting(false);
      setIschisChanged(false)
    }
  };
 */

 
  return (
    <div className={`flex mx-2 flex-col patients`}>
      <div className={`flex flex-wrap`}>
        <div className="flex-1 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
          <img className='w-100px max-sm:w-50px max-sm:text-exsm active-color mt-10 max-sm:mt-3' src={"/img/medical-team.png"} alt={`Person ${patient.firstname}`} />
          <form className="row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1" >
            <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
              <label htmlFor="firstname" className='flex-1 '>
                Nom
              </label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                defaultValue={editedPatient.firstName}
                readOnly
              />
            </div>
            <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
              <label htmlFor="lastname" className='flex-1'>
                Prénom
              </label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                defaultValue={editedPatient.lastName}
                readOnly
              />
            </div>
           {/*  <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
              <label htmlFor="age" className='flex-1'>
                Âge
              </label>
              <input
                type="text"
                id="age"
                name="age"
                defaultValue={editedPatient.age}
                readOnly
              />
            </div>
            <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
              <label htmlFor="gender" className='flex-1'>
                Sexe
              </label>
              <input
                type="text"
                id="gender"
                name="gender"
                defaultValue={editedPatient.gender}
                readOnly
              />
            </div>
            <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
              <label htmlFor="address" className='flex-1'>
                Adresse
              </label>
              <input
                type="text"
                id="address"
                name="address"
                defaultValue={editedPatient.address}
                readOnly
              />
            </div> */}
            <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
              <label htmlFor="phone" className='flex-1'>
                Téléphone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                defaultValue={editedPatient.phone}
                readOnly
              />
            </div>
            <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
              <label htmlFor="phone" className='flex-1'>
                Le Role
              </label>
              <input
                type="text"
                id="phone"
                name="role"
                defaultValue={editedPatient.role}
                readOnly
              />
            </div>
            {/* {
              isChanged && (
                isSubmitting ? (
                  <div className="loader max-sm:w-30px max-sm:border-4"></div>
                ) : (
                  <motion.button
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    transition={{ duration: 0.2 }}
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 4px gray"
                    }}
                    type="submit"
                    className="bg-blue-500 text-white p-2 rounded my-4 max-sm:text-exsm"
                    disabled={isSubmitting}
                  >
                    Sauvegarder
                  </motion.button>
                )
              )
            } */}


          </form>
        </div>
       


      </div>
    </div>
  );
}