import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';


// Imported Icons
import { BiLogOut, BiSolidDashboard } from "react-icons/bi";
import { FaCalendarAlt, FaHospitalUser, FaMoneyBill, FaUser } from "react-icons/fa";
import { FaUserDoctor, FaX } from "react-icons/fa6";
import { IoDocumentText, IoToday } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { logout } from "../store/Auth/authActions"

import { useEffect, useRef } from 'react';

import { motion } from 'framer-motion';

export default function PhoneSideNav({ openSideNav, handellOpenSideNav }) {
  const [activeLink, setActiveLink] = useState('/');
  const [beForClose,setBeforClose]=useState(false)
  const dispatch = useDispatch();
  const sideNavRef = useRef(null);
  /**
   * Handles the click event on a navigation link.
   * @param {string} to - The target URL for the navigation link.
   */

  const handleVisibilityClick = (ref, visibilitySetter, timeoutDuration) => {
    let timeoutId;
   
    return (event) => {
      clearTimeout(timeoutId);

      if (ref.current && !ref.current.contains(event.target)) {
        setBeforClose(true);
        // Wait for the specified timeout before setting visibility to false
        timeoutId = setTimeout(() => {
          visibilitySetter(false);
        }, timeoutDuration);
      }
    };
  };
  useEffect(() => {
    const timeoutDuration = 500; // Adjust this based on your timeout duration
    const handleClickOutsideSideNav = handleVisibilityClick(sideNavRef, handellOpenSideNav, timeoutDuration);

    document.addEventListener('mousedown', handleClickOutsideSideNav);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSideNav);
    };
  }, [handellOpenSideNav]);
  const handleNavLinkClick = (to) => {
    setActiveLink(to);
    setBeforClose(true);
    if (openSideNav) {
      setTimeout(() => {
        handellOpenSideNav(false);
      }, animationDuration);
    }
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const animationDuration = 100


  

  const handleOpenSideNav = () => {
    setBeforClose(true);

    if (openSideNav) {
      setTimeout(() => {
        handellOpenSideNav(false);
      }, animationDuration);
    } else {
      handellOpenSideNav(true);
    }
  };

  return (
   
       <motion.div
      
    className="slide-container phone-slide-container"
      initial={{ x: -300 ,width: 0}}
      animate={{ x: !beForClose ? 0 : -300 ,width: !beForClose ? "100%" : 0,transition: {ease: "easeIn"} }}
      exit={{ x: -300,width:0, transition: {ease: "easeInOut", delay: animationDuration / 1000 } }}
    >

     <motion.div >
     <nav className={`side-nav side-nav-active flex flex-col  m-5 p-2`} ref={sideNavRef}>
        <div className='mt-10 flex justify-between   p-5'>

          <span className='flex items-center text-3xl text-white font-extrabold bg-blue-700 p-2 rounded-lg mb-12 logo'>MedicDash</span>
          <FaX className=' text-white' onClick={handleOpenSideNav} />
        </div>

        <NavLink
          className={`side-nav-icon ${activeLink === '/' ? 'active-link' : ''}`}
          to={'/'}
          onClick={() => handleNavLinkClick('/')}
        >
          <BiSolidDashboard />
          <span className='max-sm:text-sm'>Home</span>
        </NavLink>
        <NavLink
          className={`side-nav-icon ${activeLink === '/consultations/today' ? 'active-link' : ''}`}
          to={'/consultations/today'}
          onClick={() => handleNavLinkClick('/consultations/today')}
        >
          <IoToday />
          <span className='max-sm:text-sm'>Les consultations d'aujourd'hui</span>
        </NavLink>
        <NavLink
          className={`side-nav-icon ${activeLink === '/profile' ? 'active-link' : ''}`}
          to={'/profile'}
          onClick={() => handleNavLinkClick('/profile')}
        >
          <FaUser />
          <span className='max-sm:text-sm'>Mon profile</span>
        </NavLink>
        <NavLink
          className={`side-nav-icon ${activeLink === '/patient' ? 'active-link' : ''}`}
          to={'/patient'}
          onClick={() => handleNavLinkClick('/patient')}
        >
          <FaHospitalUser />
          <span className='max-sm:text-sm'>Les Patients</span>
        </NavLink>
        <NavLink
          className={`side-nav-icon ${activeLink === '/consultations' ? 'active-link' : ''}`}
          to={'/consultations'}
          onClick={() => handleNavLinkClick('/consultations')}
        >
          <FaUserDoctor />
          <span className='max-sm:text-sm'>Les consultations</span>
        </NavLink>
        <NavLink
          className={`side-nav-icon ${activeLink === '/document' ? 'active-link' : ''}`}
          to={'/document'}
          onClick={() => handleNavLinkClick('/document')}
        >
          <IoDocumentText />
          <span className='max-sm:text-sm'>Les documents</span>
        </NavLink>
        <NavLink
          className={`side-nav-icon ${activeLink === '/appointments' ? 'active-link' : ''}`}
          to={'/appointments'}
          onClick={() => handleNavLinkClick('/appointments')}
        >
          <FaCalendarAlt />
          <span className='max-sm:text-sm'>Les rendez-vous</span>
        </NavLink>
        <NavLink
          className={`side-nav-icon ${activeLink === '/money' ? 'active-link' : ''}`}
          to={'/money'}
          onClick={() => handleNavLinkClick('/money')}
        >
          <FaMoneyBill />
          <span className='max-sm:text-sm'>Les factures</span>
        </NavLink>
        <button
          className={`side-nav-icon ${activeLink === '/logout' ? 'active-link' : ''}`}

          onClick={handleLogout}
        >
          <BiLogOut />
          <span className='max-sm:text-sm'>Se déconnecter</span>
        </button>
      </nav>
     </motion.div>
     
        
      
    </motion.div>

  );
}
