import React from 'react';

import { motion } from "framer-motion"
import { FaX } from 'react-icons/fa6';
export default function Appointment({ appointment, forwardedRef,setShowBackDRropAlertMessage, setValueOfBackdrop, setValueOfUrl }) {
  const formattedDate = (dateOfBirth) => {

    return new Date(dateOfBirth).toISOString().split('T')[0]

  };
  const handellDelete = () => {
    setValueOfUrl(`appointments/${appointment._id}`)
    setShowBackDRropAlertMessage(true)
    setValueOfBackdrop(appointment.firstName + " " + appointment.lastName)

  };
  return (
    <div ref={forwardedRef} className="flex-1 flex-col max-sm:p-2 nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max">
      <div className="flex w-full justify-end color-error text-xl max-sm:text-sm">
        <motion.div transition={{ duration: 0.1 }}
          whileHover={{
            scale: 1.4,
            textShadow: "0px 0px 4px gray"
          }}>
          <button onClick={handellDelete} className='m-2 px-4'><FaX /></button>
        </motion.div>
      </div>
      <img className='w-100px max-sm:w-50px mt-4 max-sm:mt-1' src="/img/deadline.png" alt={`Person ${appointment.id}`} />
      <form className="row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1">
        {/* Display nurse information here using the 'nurse' prop */}
        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
          <label htmlFor="firstname" className='flex-1 '>
            Nom
          </label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            value={appointment.firstName}
            readOnly
          />
        </div>
        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
          <label htmlFor="lastname" className='flex-1'>
            Prénom
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            value={appointment.lastName}
            readOnly
          />
        </div>
        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
          <label htmlFor="lastname" className='flex-1'>
          Rendez-vous
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            value={formattedDate(appointment.date)}
            readOnly
          />
        </div>
        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
          <label htmlFor="lastname" className='flex-1'>
          La raison
          </label>
          <textarea
          rows={4}
            type="text"
            id="lastname"
            name="lastname"
            value={appointment.reason}
            readOnly
          />
        </div>
        
        
      </form>
    </div>
  );
}
