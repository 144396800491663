import React, { useEffect, useRef, useState } from 'react';
import { FaX } from 'react-icons/fa6';
import { motion } from 'framer-motion';
import Message from '../Components/Message';
import { IoIosSend } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import api from '../config';
import socketIOClient from 'socket.io-client';
import { setNotificationCounter } from '../store/notification/notificationAction';
import { REACT_APP_API_URL } from '../URL';
import LoadingMessages from '../Components/LoadingMessages';



let socket
export default function Messages({ setOpenMessages, openMessages }) {
  const [messages, setMessages] = useState(null);
  const [visibleMessages, setVisibleMessages] = useState(10);
  const [beForClose, setBeforClose] = useState(false);
  const sideNavRef = useRef(null);
  const messagesEndRef = useRef(null); // Reference for the end of the messages list
  const animationDuration = 100;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token); // Assuming 'auth' is your authentication slice
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [name, setName] = useState(null)
  const [notifications, setNotification] = useState(0)
  useEffect(() => {
    socket = socketIOClient(REACT_APP_API_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}` // Pass the token in the socket connection
      }
    });
    socket.emit("setup", user);
    socket.on("connected", () => setSocketConnected(true));
    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('conversations/all', {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Handle the response as needed
        setMessages(response.data)
        socket.emit("join conversation", user.team, user)
      } catch (error) {
        // Handle errors

      } finally {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, [dispatch, token]);
  useEffect(() => {
    socket.on("message recieved", (newConversation) => {
      if ((newConversation.sender !== user._id) && openMessages) {
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          updatedMessages.unshift(newConversation);
          return updatedMessages;
        });
        dispatch(setNotificationCounter(0));
      }
      if ((newConversation.sender !== user._id) && !openMessages) {
        dispatch(setNotificationCounter(notifications + 1));
        setNotification(notifications + 1);
      }
    });
  }, [openMessages, user._id, dispatch, notifications]);




  const handleOpenMessages = () => {
    setBeforClose(true);

    if (openMessages) {
      setTimeout(() => {
        setOpenMessages(!openMessages);
      }, animationDuration);
    } else {
      setOpenMessages(openMessages);
    }
  };

  const handleVisibilityClick = (ref, visibilitySetter, timeoutDuration) => {
    let timeoutId;

    return (event) => {
      clearTimeout(timeoutId);

      if (ref.current && !ref.current.contains(event.target)) {
        setBeforClose(true);
        timeoutId = setTimeout(() => {
          visibilitySetter(false);
        }, timeoutDuration);
      }
    };
  };



  useEffect(() => {
    const timeoutDuration = 500;
    const handleClickOutsideSideNav = handleVisibilityClick(sideNavRef, handleOpenMessages, timeoutDuration);

    document.addEventListener('mousedown', handleClickOutsideSideNav);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSideNav);
    };
  }, [openMessages]);

  useEffect(() => {
    // Scroll to the bottom of the messages when the component updates
    if (!loading && openMessages) {

      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

    }
  }, [messages, loading, openMessages]);

  const handleLoadMore = () => {
    setVisibleMessages((prevVisibleMessages) => prevVisibleMessages + 5);
  };
  const typingHandler = (e) => {
    setNewMessage(e.target.value);
    if (!socketConnected) return;
    
    if (!typing) {
      setTyping(true);
      socket.emit("typing", user.team);
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        socket.emit("stop typing", user.team);
        setTyping(false);
      }
    }, timerLength);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Add logic to send the message to the backend
    const response = await api.post('conversations/send', { text: newMessage }, {
      headers: {
        token: `Bearer ${token}`, // Include the token in the Authorization header
      },
    });
    const responseData = response.data.conversation;
    socket.emit("new message", user.team, responseData);

    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      updatedMessages.unshift(responseData);
      return updatedMessages;
    })
    setNewMessage("")
    // Handle the response as needed
  };
  return (
    <div>
      {
        openMessages &&

        <motion.div
          className='messages backdrop-blur-md flex flex-col items-center w-60% max-sm:w-100%'
          initial={{ width: 0 }}
          animate={{ width: !beForClose ? "60%" : 0, transition: { ease: "easeIn" } }}
          exit={{ width: 0, transition: { ease: "easeInOut", delay: animationDuration / 1000 } }}
        >
          {

            loading ? <LoadingMessages /> : <>
              <motion.div
                className='flex w-full justify-start'
                initial={{ opacity: 0 }}
                animate={{ opacity:1, transition: { duration: 0.3, delay: 0.4 } }}
                exit={{ opacity: 0, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
              >
                <FaX onClick={handleOpenMessages} className='color-error' />
              </motion.div>
              <motion.div
                className='flex w-full justify-start'
                initial={{ opacity:0}}
                animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.5 } }}
                exit={{ opacity: 0, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
              >
                <div>

                </div>
                <h1 className="m-5 flex justify-center w-full">Conversation</h1>
              </motion.div>
              <motion.div
                className='flex flex-col-reverse overflow-x-hidden overflow-y-auto messagesRow w-full'
                style={{ height: 'calc(100% - 150px)' }} // Adjust the height as needed
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.6 } }}
                exit={{ opacity: 0, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
              >
                <div className='conversationBox mt-5 w-full overflow-x-hidden'>
                  {visibleMessages < messages.length && (
                    <button className="load-more flex justify-center w-full" onClick={handleLoadMore}>
                      Load More
                    </button>
                  )}
                  {messages.slice(0, visibleMessages).reverse().map((message, index) => (
                    <Message key={index} message={message} i={index} name={name} setName={setName} />
                  ))}
                  {
                    isTyping && <div className='typingRow p-8'>
                      <div className="typing ">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <span className='name'>{name}</span>
                    </div>
                  }
                  <div ref={messagesEndRef} /> {/* Reference for the end of the messages */}
                </div>
              </motion.div>
              <motion.div
                className='formDiv'
                initial={{ x: 1000 }}
                animate={{ x: 0, transition: { duration: 0.3, delay: 0.7 } }}
                exit={{ x: 1000, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
              >

                <form action="" onSubmit={handleSubmit}>
                  <input type="text"
                    value={newMessage}
                    onChange={typingHandler} />
                  <button><IoIosSend /></button>
                </form>
              </motion.div>
            </>
          }

        </motion.div>

      }
    </div>
  );
}
