import React, { useEffect, useState } from 'react';

import { IoChatbox } from 'react-icons/io5';

import { MdOutlineMenu } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetNotificationCounter} from '../store/notification/notificationAction';


// Function to format the date
const formatDate = (date) => {
  const options = { day: 'numeric', month: 'short' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

// Function to format the time
const formatTime = (date) => {
  const options = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedTime;
};

/**
 * TopNav component for the top navigation bar.
 * @param {function} handellOpenSideNav - Function to handle the opening of the side navigation.
 */
export default function TopNav({ handellOpenSideNav ,openSideNav,setOpenMessages,openMessages}) {
  const dispatch = useDispatch();
  const  user  = useSelector((state) => state.auth.user); // Assuming 'auth' is your authentication slice
  const   notificationCounter= useSelector((state) => state.notification.notificationCounter);
 
  const [currentDate, setCurrentDate] = useState(new Date());

  // Function to handle opening/closing of the side navigation
  const handleOpenSideNav = () => {
   
    handellOpenSideNav(openSideNav);
  };
  const handleOpenMessages = ()=>{
    setOpenMessages(!openMessages)
    dispatch(resetNotificationCounter());
  }
 

  // Update the current date and time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Format the date and time
  const formattedDate = formatDate(currentDate);
  const formattedTime = formatTime(currentDate);

  return (
    <nav className={'top-nav flex items-center m-5 p-4 flex-col' }>
      <div className="flex items-center w-full ">
       {
        !openSideNav &&  <MdOutlineMenu className='menu-visible-icon' onClick={handleOpenSideNav} />
       }
        <div className="nav-container flex items-center justify-between w-full">

          <div className='flex justify-between items-center max-sm:order-2 gap-2'>
            <span className='bg-white p-2 rounded-md max-sm:text-exsm'>Bounjour {user.firstName+" "+user.lastName}</span>
            <span className='bg-white p-2 rounded-md max-sm:hidden '>{formattedDate} {formattedTime}</span>
          </div>

          <div className='right-top-nav w-36 flex items-center justify-between md:justify-end sm:justify-end max-sm:order-3'>
            
            <div className='md:mr-4 w-8 h-8 text-xl'>
              
              <IoChatbox className='right-top-nav-icon' onClick={handleOpenMessages}/>
              {
                 notificationCounter>0 && <span className='messageNotification top-8 right-70px max-sm:top-3 max-sm:right-12'>{notificationCounter}</span>
              }
            </div>
            <Link to={'/profile'}> <img src={"/img/medical-team.png"}  alt="" className='w-8 h-8' /></Link>
            
          </div>
        </div>
        
      </div>
      
    </nav>
  );
}
