// consultationProfile.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'

import { AnimatePresence, motion } from "framer-motion"


import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import api from '../config';




export default function AddAppointment({ setShowBackDRropAlertMessage, setValueOfBackdrop }) {
  const { id } = useParams();
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    date:'',
    patientId: '',
    reason:""
  });
  
const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const token = useSelector((state) => state.auth.token)
  const [patient, setPatient] = useState(null);
  const [allPatient, setAllPatient] = useState(null);
  const [errors, setErrors] = useState(false);

  const fetchData = async () => {
    try {
      if (id) {
        const response = await api.get(`patients/${id}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        });
        setPatient(response.data);
        setFilteredData(response.data); 
      // Ensure filteredData contains the single patient
      } else {
        const responseAll = await api.get(`patients/all`, {
          headers: {
            token: `Bearer ${token}`,
          },
        });
      
        setAllPatient(responseAll.data);
        setFilteredData(responseAll.data); // Set filteredData initially to all patients
      }
    } catch (error) {
      // Handle errors
      console.error('Error fetching data:', error);
    }
  };
  

   

  useEffect(() => {
    fetchData();
  }, [id, token]);
  useEffect(() => {
    // Filter data based on the search term
    const filtered = allPatient?.filter((patient) => {
      const fullName = `${patient.firstName} ${patient.lastName}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredData(filtered);
  }, [ searchTerm,allPatient]);
 
  const handleSelectChange = (e) => {
    const selectedPatient = filteredData.find((patient) => {
      const fullName = `${patient.firstName} ${patient.lastName}`;
      return fullName.toLowerCase() === e.target.value.toLowerCase();
    });
    setPatient(selectedPatient);
  };
  const handleSubmit = async (e) => {
    const hasEmptyInput = Object.entries(inputValues).some(([key, value]) => {
      // Exclude the 'img' field from the check
      if (key === 'firstName' || key === 'lastName' || key === 'phone'|| key === 'patientId' ) return false;
      // Check if the value is empty
      return value.trim() === '';
    });

    // Set errors to true if any input value is empty, otherwise set it to false
    setErrors(hasEmptyInput);
    e.preventDefault();
    if (!hasEmptyInput) {
      try {
        setIsSubmitting(true);
        // Create a new user object with input values
        const newUser = {
          firstName: patient.firstName,
          lastName: patient.lastName,
          phone: patient.phone,
          date: inputValues.date, // Include the formatted date in the user object
          patientId: patient._id,
          reason:inputValues.reason
        };
  
       await api.post('appointments/create', newUser,{
          headers: {
            token: `Bearer ${token}`, // Correct the header key to 'Authorization'
          },
        });
        
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(`/patient-profile/${id? id :newUser.patientId}`);
      } catch (error) {
        console.error('Error updating consultation data:', error);
      }
    }
    setIsSubmitting(false);


  };

  const handleInputChange = (e) => {
    setErrors(false)
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  
  return (
    <div className={`flex mx-2 flex-col consultations`} >
   
       <div className="flex header flex-wrap flex-col backdrop-blur-sm addConsultation w-full p-4 my-3">

        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
          <label htmlFor="" className='flex-1'>Trouver un patient</label>
          <input
            type="text"
            placeholder="Search for a patient"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
          <label htmlFor="" className='flex-1'>Sélectionnez un patient</label>

          <select onChange={handleSelectChange}>
            <option value="">Select a patient</option>
            {filteredData?.map((patient,i) => {
              const fullName = `${patient.firstName} ${patient.lastName}`;
              return (
                <option key={i} value={fullName}>
                  {fullName}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      {
        patient && 
      <form onSubmit={handleSubmit} className="flex flex-wrap flex-col backdrop-blur-sm addConsultation w-full p-4" >
        <div className={`flex flex-wrap`}>
        <div className="flex-1 flex-col border-2 rounded-md border-blue-300  mx-3 my-2 flex  p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
           <h1 className='w-full p-4 text-xl max-sm:text-sm'>  Detail De Patient</h1>
           <img className='w-100px max-sm:w-50px mt-10 max-sm:mt-3' src={patient?.img} alt={`Person `} />
           <div className='row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1'>
             <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
               <label htmlFor="firstname" className='flex-1 '>
                 Nom
               </label>
               <input
                
                 type="text"
                 id="firstName"
                 name="firstname"
                 defaultValue={patient.firstName}
                 readOnly

               />
             </div>
             <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
               <label htmlFor="lastName" className='flex-1'>
                 Prénom
               </label>
               <input
                
                 type="text"
                 id="lastname"
                 name="lastname"
                 defaultValue={patient.lastName}
                 readOnly
               />
             </div>
           
             <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
               <label htmlFor="phone" className='flex-1'>
                 Téléphone
               </label>
               <input
                
                 type="text"
                 id="phone"
                 name="phone"
                 defaultValue={patient.phone}
                 readOnly
               />
             </div>
             <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
               <label htmlFor="phone" className='flex-1'>
               Rendez-vous
               </label>
               <input
                 onChange={handleInputChange}
                 type="date"
                 id="phone"
                 name="date"

                 
               />
             </div>
             <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-start'>
               <label htmlFor="phone" className='flex-1'>
               La raison
               </label>
               <textarea
                 onChange={handleInputChange}
                 type="text"
                 id="phone"
                 name="reason"
                 rows={4}

                 
               />
             </div>
           </div>
         </div>

      </div>
      
        {
          isSubmitting ?
            <div className='flex justify-center'><div className="loader max-sm:w-30px max-sm:border-4"></div></div>
            :
            <div className='flex justify-center'>
              <motion.button
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                transition={{ duration: 0.2 }}
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray"
                }}
                type="submit"
                className="bg-blue-500 text-white p-2 rounded my-4 max-sm:text-exsm w-30% "
                disabled={isSubmitting}
              >
                Sauvegarder
              </motion.button>
            </div>

        }
         <AnimatePresence>
          {errors &&
            <motion.div
              className="errorBox p-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
              exit={{ opacity: 0, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
            >
              <h1>
              Veuillez remplir tous les champs avant de soumettre le formulaire.
              </h1>
            </motion.div>
          }
        </AnimatePresence>
      </form>
 }

    </div>




  );
}