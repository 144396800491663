import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
const widthClassNames = ['width-15', 'width-20', 'width-25'];
const widthValues = ['width-15', 'width-20', 'width-25'];

export default function LoadingMessages() {
  const [directions, setDirections] = useState([]);
  const [widthClass, setWidthClass] = useState('');
  const [widthClasses, setWidthClasses] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * widthClassNames.length);
      setWidthClass(widthClassNames[randomIndex]);
    }, 1000); // Adjust interval as needed

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    // Initial generation of span classes and directions
    generateWidthClassesAndDirections();

    // Set interval to regenerate span classes and directions every 2 seconds
    const interval = setInterval(() => {
      generateWidthClassesAndDirections();
    }, 2000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const generateWidthClassesAndDirections = () => {
    const newWidthClasses = Array.from({ length: 10 }, () => {
      const randomWidth = Math.floor(Math.random() * 26); // Generate a random number between 0 and 25
      return `width-${randomWidth}`;
    });
    setWidthClasses(newWidthClasses);

    const newDirections = Array.from({ length: 10 }, () => {
      return Math.random() < 0.5 ? 'from' : 'to'; // Randomly choose 'from' or 'to'
    });
    setDirections(newDirections);
  };
    const animationDuration = 100;
    return (
       
        <div className='loadingMessages'>
          
        <motion.div
          className='flex w-full justify-start'
          initial={{ x: 600 }}
          animate={{ x: 0, transition: { duration: 0.3, delay: 0.4 } }}
          exit={{ x: 600, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
        >
        <div className='top'>
        {[...Array(2)].map((_, index) => (
          <span key={index} className={`${widthClass}`}></span>
        ))}
      
        </div>
        </motion.div>
        <motion.div
          className='flex w-full justify-start'
          initial={{ x: 600 }}
          animate={{ x: 0, transition: { duration: 0.3, delay: 0.5 } }}
          exit={{ x: 600, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
        >
          <div>

          </div>
          <h1 className="m-5 flex justify-center w-full">{[...Array(1)].map((_, index) => (
          <span key={index} className={`${widthClass}`}></span>
        ))}</h1>
        </motion.div>
        <motion.div
          className='flex flex-col-reverse overflow-x-hidden overflow-y-auto messagesRow w-full'
          style={{ height: 'calc(100% - 150px)' }} // Adjust the height as needed
          initial={{ x: 1000 }}
          animate={{ x: 0, transition: { duration: 0.3, delay: 0.6 } }}
          exit={{ x: 1000, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
        >
          <div className='conversationBox mt-5 w-full overflow-x-hidden'>
          {widthClasses.map((widthClass, index) => (
            <motion.div
       
            key={index}
            className={`p-5 ${directions[index]}`}
          >
    
          <span key={index} className={widthClass}></span>
          </motion.div>
          
        ))}
            
          </div>
        </motion.div>
        <motion.div
          className='formDiv'
          initial={{ x: 1000 }}
          animate={{ x: 0, transition: { duration: 0.3, delay: 0.7} }}
          exit={{ x: 1000, transition: { ease: "easeInOut", duration: animationDuration / 1000, delay: animationDuration / 1000 } }}
        >

      </motion.div>
        </div>
    )
}
