import axios from 'axios';
import { REACT_APP_API_URL } from './URL';

const api = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/`,
});

// Add a response interceptor to handle errors
api.interceptors.response.use(
  // If the response is successful, return it as is
  (response) => response,
  // If there's an error, check and handle it
  (error) => {
    // Check if the error is related to unauthorized access
    if (error.response && error.response.status === 401) {
      // Handle the unauthorized access error internally without logging to the console
      return Promise.reject(error);
    } else {
      // For other types of errors, log the error message to the console
      console.error(error);
      return Promise.reject(error);
    }
  }
);

export default api;

