import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { FaUserEdit } from 'react-icons/fa';
import api from '../config';
import { useSelector } from 'react-redux';

export default function ActualPatient({consultation}) {
  const [patient, setPatient] = useState(null);
  const token = useSelector((state) => state.auth.token); // Assuming 'auth' is your authentication slice
  const navigate = useNavigate()
useEffect(() => {

  const fetchData = async () => {
    try {
     
     

      const response = await api.get(`patients/${consultation.patientId}`, {
        headers: {
          token: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      // Handle the response as needed
      setPatient(response.data)
      
    } catch (error) {
     
    }
  };

  fetchData(); // Invoke the fetchData function
}, [token]);
const handellConfirme = async () => {
       
  navigate("/consultation/"+consultation._id+"/true")

};
const formattedDate = (dateOfBirth) => {

  return new Date(dateOfBirth).toISOString().split('T')[0]

};
function calculateAge(dateOfBirth) {
  const birthDate = new Date(dateOfBirth);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Adjust age based on the month and day
  if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}
  return (
    <div className=" flex flex-1 flex-col atualPatient  mr-3 patient-home backdrop-blur-sm p-4   items-center justify-center max-sm:min-w-full max-sm:m-0 ">
      <div className='flex justify-between w-full'>
        <button onClick={handellConfirme} className='btn max-sm:text-exsm text-md bg-red-400'>Terminer</button>
        <h1 className='isIn max-sm:text-exsm text-md'>En Cours</h1>
      </div>
      
      <img src={patient?.img} alt="" className='w-100px max-sm:w-50px'/>
      <div className=" row flex flex-1 flex-col mr-3 patient-home backdrop-blur-sm p-4  items-center justify-center w-full mt-4">
        <Link className=' mb-4 flex justify-end w-full'><FaUserEdit className='btn max-sm:text-xl text-3xl bg-red-400'/></Link>
        <h1 className='isIn max-sm:text-exsm text-md'>Mr.{patient?.firstName} {patient?.lastName}</h1>
      
        <div className='w-60% flex gap-3 p-3 max-sm:text-exsm mt-3'>
          <span className='flex-1'>date de naissance</span>
          <span>{patient && formattedDate(patient?.dateOfBirth)}</span>
        </div>
        <div className='w-60% flex gap-3  p-3 max-sm:text-exsm'>
          <span className='flex-1'>L'age</span>
          <span>{patient &&  calculateAge(formattedDate(patient?.dateOfBirth))}</span>
        </div>
        <div className='w-60% flex gap-3  p-3 max-sm:text-exsm'>
          <span className='flex-1'>Date d'inscription</span>
          <span>{patient &&  formattedDate(patient.createdAt)}</span>
        </div>
      </div>

    </div>

  )
}
