// PatientProfile.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


import { motion } from "framer-motion"
import { FaRulerVertical, FaWeightHanging, FaX } from 'react-icons/fa6';
import { FaHeart, FaFlask } from 'react-icons/fa';
import { MdBloodtype } from "react-icons/md";

import CusCalendar from '../Components/CusCalendar';
import Table from '../Components/Table';
import { useDispatch, useSelector } from 'react-redux';
import api from '../config';
import LoadingPage from '../Components/LoadingPage';

export default function PatientProfile({ setShowBackDRropAlertMessage, setValueOfBackdrop, setValueOfUrl }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token); // Assuming 'auth' is your authentication slice
  const { id } = useParams();
  const [patient, setPatient] = useState(null);
  const [consultation, setConsultation] = useState(null);
  const [lastConsultation, setLastConsultation] = useState(null);
  const [loading, setLoading] = useState(true);

  const [editedPatient, setEditedPatient] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIschisChanged] = useState(false);
  const navigate = useNavigate()
  const [appointment, setAppointment] = useState(null);
  const [appointments, setAppointments] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await api.get(`patients/${id}`, {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        const consultationResponse = await api.get(`consultations/by-patient/${id}`, {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        
        const appointmentsResponse = await api.get(`appointments/by-patient/${id}`, {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setAppointments(appointmentsResponse.data)

        try{
          const appointmentResponse = await api.get(`appointments/${id}`, {
            headers: {
              token: `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          setAppointment(appointmentResponse.data)
        }
        catch{
          setAppointment(null)
        }
        // Handle the response as needed
        setPatient(response.data)
       
        setConsultation(consultationResponse.data)
        setLastConsultation(consultationResponse.data[0])
      } catch (error) {
        navigate("/patient");
      } finally {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, [dispatch, token, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the new value is different from the existing value
    const isValueChanged = patient[name] !== value;

    // Update the editedPatient state and isChanged state
    setEditedPatient((prevPatient) => ({
      ...prevPatient,
      [name]: isValueChanged ? value : patient[name],
    }));

    setIschisChanged(isValueChanged);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      // Update the data.json file with the edited patient data
      const response = await api.put(`patients/${id}`, editedPatient, {
        headers: {
          token: `Bearer ${token}`, // Correct the header key to 'Authorization'
        },
      });



      // Simulate a delay (you can replace this with an actual API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Update the local state with the edited patient data
      setPatient(response.data.patient);

      // You can update the global state or perform other actions as needed

    } catch (error) {
      console.error('Error updating patient data:', error);
    } finally {
      setIsSubmitting(false);
      setIschisChanged(false)
    }
  };


  const handellDelete = () => {
    setValueOfUrl(`patients/${id}`)
    setShowBackDRropAlertMessage(true)
    setValueOfBackdrop(patient.firstName + " " + patient.lastName)

  };
  function calculateAge(dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age based on the month and day
    if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
  const formattedDate = (dateOfBirth) => {

    return new Date(dateOfBirth).toISOString().split('T')[0]

  };

  const filteredData = consultation?.map(({ _id, updatedAt, price, firstName, lastName }) => ({
    _id,
    price,
    updatedAt: formattedDate(updatedAt), // Corrected usage of formattedDate function
    firstName,
    lastName,
  }));
  const filteredAppointment = appointments?.map(({ _id, date, firstName, lastName, reason }) => ({
    _id,
    date: formattedDate(date), // Corrected usage of formattedDate function
    firstName,
    lastName,
    reason
  }));



  return (
    <div>
      {
        loading ? <LoadingPage /> :
          <div className={`flex mx-2 flex-col patients  max-sm:w-full`}>
            {
              patient ? <div className={`flex flex-wrap`}>
                <div className="flex-1 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max ">
                  <div className="flex w-full justify-end color-error text-xl max-sm:text-sm"><motion.button transition={{ duration: 0.1 }}
                    whileHover={{
                      scale: 1.4,
                      textShadow: "0px 0px 4px gray"
                    }}> <FaX onClick={handellDelete}></FaX></motion.button></div>
                  <img className='w-100px max-sm:w-50px max-sm:text-exsm active-color mt-10 max-sm:mt-3' src={patient.img} alt={`Person ${patient.firstname}`} />
                  <form className="row flex flex-1 flex-col p-4 items-center justify-center w-full mt-4 max-sm:p-1" onSubmit={handleSubmit}>
                    <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                      <label htmlFor="firstname" className='flex-1 '>
                        Nom
                      </label>
                      <input
                        type="text"
                        id="firstname"
                        name="firstName"
                        defaultValue={patient.firstName}

                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                      <label htmlFor="lastname" className='flex-1'>
                        Prénom
                      </label>
                      <input
                        type="text"
                        id="lastname"
                        name="lastName"
                        defaultValue={patient.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                      <label htmlFor="age" className='flex-1'>
                        Date de naissance
                      </label>
                      <input
                        type="date"
                        id="age"
                        name='dateOfBirth'
                        defaultValue={formattedDate(patient.dateOfBirth)}
                        onChange={handleInputChange}

                      />
                    </div>
                    <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                      <label htmlFor="age" className='flex-1'>
                        Carte d'identité nationale
                      </label>
                      <input
                        type="text"
                        id="age"
                        name='cin'
                        defaultValue={patient.cin}
                        onChange={handleInputChange}

                      />
                    </div>
                    <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                      <label htmlFor="age" className='flex-1'>
                        Âge
                      </label>
                      <input
                        type="text"
                        defaultValue={calculateAge(patient.dateOfBirth)}

                        readOnly
                      />
                    </div>
                    <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
                      <label htmlFor="maritalStatus" className='w-30%'>
                        Le genre
                      </label>
                      <select
                        id="maritalStatus"
                        name="gender"
                        defaultValue={patient.gender}
                        onChange={handleInputChange}
                      >
                        <option>Sélectionner</option>
                        <option value="Féminin">Féminin</option>
                        <option value="Masculin">Masculin</option>
                      </select>
                    </div>
                    <div className='w-full flex gap-3 p-3 items-center max-sm:text-xs'>
                      <label htmlFor="maritalStatus" className='w-30%'>
                        Situation Familiale
                      </label>
                      <select
                        id="maritalStatus"
                        name="familySituation"
                        defaultValue={patient.familySituation}
                        onChange={handleInputChange}
                      >
                        <option value="">Sélectionner</option>
                        <option value="Marié(e)">Marié(e)</option>
                        <option value="Célibataire">Célibataire</option>
                      </select>
                    </div>
                    <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                      <label htmlFor="address" className='flex-1'>
                        Adresse
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        defaultValue={patient.address}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='w-full flex gap-3 p-3 max-sm:text-exsm items-center'>
                      <label htmlFor="phone" className='flex-1'>
                        Téléphone
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        defaultValue={patient.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    {
                      isChanged && (
                        isSubmitting ? (
                          <div className="loader max-sm:w-30px max-sm:border-4"></div>
                        ) : (
                          <motion.button
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            transition={{ duration: 0.2 }}
                            whileHover={{
                              scale: 1.1,
                              textShadow: "0px 0px 4px gray"
                            }}
                            type="submit"
                            className="bg-blue-500 text-white p-2 rounded my-4 max-sm:text-exsm"
                            disabled={isSubmitting}
                          >
                            Sauvegarder
                          </motion.button>
                        )
                      )
                    }


                  </form>
                </div>
                <div className='flex flex-col max-lg:w-full'>
                  <div className="max-lg:order-2 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max">
                    <h3 className='mx-2 text-lg max-sm:text-sm'>Prochain rendez-vous</h3>

                    {
                      appointment ? <CusCalendar initialDate={appointment.date} /> :
                      <div className='flex flex-col gap-5 w-full justify-center items-center h-72 max-sm:h-auto'>
                         <h1 className=' max-sm:text-exsm'>Pas de Prochain rendez-vous</h1>
                         </div>
                    }
                  </div>
                  <div className="max-lg:order-1 gap-10 w- full flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max">
                    <h3 className='mx-2 text-lg max-sm:text-sm'>Les détails de patient </h3>
                    {
                      lastConsultation ? <div className='flex flex-col gap-8 w-full'>
                        <div className='flex items-center'>
                          <div className='flex items-center w-full'>
                            <FaRulerVertical className='w-50px max-sm:text-exsm active-color' />
                            <span className='max-sm:text-exsm'>La taille</span>
                          </div>
                          <span className='w-30% max-sm:text-exsm'>{lastConsultation.height} Cm</span>
                        </div>
                        <div className='flex items-center'>
                          <div className='flex items-center w-full'>
                            <FaWeightHanging className='w-50px max-sm:text-exsm active-color' />
                            <span className='max-sm:text-exsm'>Le poids</span>
                          </div>
                          <span className='w-30% max-sm:text-exsm'>{lastConsultation.weight} Kg</span>
                        </div>
                        <div className='flex items-center'>
                          <div className='flex items-center w-full'>
                            <FaHeart className='w-50px max-sm:text-exsm active-color' />
                            <span className='max-sm:text-exsm'>Le battement du cœur</span>
                          </div>
                          <span className='w-30% max-sm:text-exsm'>{lastConsultation.bdc} bpm</span>
                        </div>
                        <div className='flex items-center'>
                          <div className='flex items-center w-full'>
                            <MdBloodtype className='w-50px max-sm:text-exsm active-color' />
                            <span className='max-sm:text-exsm'>Pression artérielle</span>
                          </div>
                          <span className='w-30% max-sm:text-exsm'>{lastConsultation.bloodPressure} mg/dL</span>
                        </div>
                        <div className='flex items-center'>
                          <div className='flex items-center w-full'>
                            <FaFlask className='w-50px max-sm:text-exsm active-color' />
                            <span className='max-sm:text-exsm'>La glycémie</span>
                          </div>
                          <span className='w-30% max-sm:text-exsm'>{lastConsultation.bloodSugar} mg/dL</span>
                        </div>
                      </div> : <div className='flex flex-col gap-5 w-full justify-center items-center h-96 max-sm:h-auto'>
                        <h1 className=' max-sm:text-exsm'>Pas des détails</h1>
                      </div>
                    }
                  </div>
                </div>
                <Table isView={true} isDelete={true} addUrl={"/add-consultation/" + id} viewUrl="/consultation/" url="consultations/" action={true} counterName="consultation" title='Les Consultations' data={filteredData} setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl} />
                <Table isDelete={true} url={'appointments/'} addUrl={"/add-appointment/"} action={true} title='Les Rendez-Vous' counterName="Rendez-Vous" data={filteredAppointment} setShowBackDRropAlertMessage={setShowBackDRropAlertMessage} setValueOfBackdrop={setValueOfBackdrop} setValueOfUrl={setValueOfUrl} />

              </div> :
                <div className="flex-1 flex-col nurseProfile mx-3 my-2 flex backdrop-blur-sm p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max max-sm:p-0">
                  <h1>Il n'y a aucun patient trouvé</h1>

                </div>
            }
          </div>
      }</div>
  );
}