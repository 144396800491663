import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../config';
import { loginSuccess, loginFailure } from '../store/Auth/authActions'; // Replace with the actual path
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AnimatePresence, motion } from 'framer-motion';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector((state) => state.auth.error);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
    dispatch(loginFailure(false));
  };

  const handleFormSubmit = async (e) => {
    // Prevent the default form submission behavior
    e.preventDefault();

    // Check if email and password fields are not empty
    if (!formData.email || !formData.password) {
      // Dispatch an action indicating that email or password is empty
      dispatch(loginFailure("Veuillez saisir votre adresse e-mail et votre mot de passe."));
      return; // Exit the function
    }

    try {
      setIsSubmitting(true)
      const response = await api.post('auth/login', formData);

      // Extract the token from the response
      const token = response.data.token;

      // Dispatch the login success action with token and user data
      dispatch(loginSuccess(token, response.data.user));

      // Redirect the user to the home page


      navigate("/");

      // Clear the form data after successful login
      setFormData({
        email: '',
        password: '',
        remember: false,
      });
    } catch {
      setIsSubmitting(false)
      dispatch(loginFailure("Connexion échouée. Identifiants invalides."));
    }
    setIsSubmitting(false)
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  };

  // Save the original console.error function
  const originalConsoleError = console.error;

  // Override console.error to filter out the specific network error message


  return (
    <div className="auth flex items-center justify-center max-sm:min-w-full max-sm:m-0 min-w-max bg-img max-sm:justify-normal">
      <form
        className="row flex flex-col p-4 backdrop-blur-md items-center justify-center md:w-60% lg:w-40% max-sm:w-full mt-1 max-sm:my-32 mx-2 max-sm:rounded-lg drop-shadow-sm"
        onSubmit={handleFormSubmit}
      >
        <h1 className="text-3xl max-sm:text-sm p-4 m-3">Connexion</h1>
        <div className="w-full flex gap-3 p-3 max-sm:text-exsm items-center">
          <label htmlFor="email" className="w-30%">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="exemple@mail.com"
            required
            className={error ? 'error' : ''}
          />
        </div>
        <div className="w-full flex gap-3 p-3 items-center max-sm:text-exsm">
          <label htmlFor="password" className="w-30%">
            Mot de passe
          </label>
          <div className={error ? "error inputGroup flex items-center gap-1" : "inputGroup flex items-center gap-1"}>
            <input
              type={passwordVisibility ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Mot de passe..."
              required
            />
            <button onClick={handlePasswordVisibility} type='button' className='main-color ' >{
              passwordVisibility ? <FaEyeSlash /> : <FaEye />
            }
            </button>
          </div>
        </div>
        <div className="w-full flex gap-3 p-3 items-center max-sm:text-exsm">
          <label htmlFor="remember" className="w-30%">
            Se souvenir de moi
          </label>
          <input
            type="checkbox"
            id="remember"
            name="remember"
            checked={formData.remember}
            onChange={handleInputChange}
          />
        </div>
        <AnimatePresence>
          {
            isSubmitting ?
              <div className='flex justify-center'><div className="loader max-sm:w-30px max-sm:border-4"></div></div>
              :
              <motion.div

                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
                exit={{ opacity: 0, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray"
                }} className="flex justify-center w-full my-6">
                <button type="submit" className="btn max-sm:text-exsm text-md bg-green-400">
                  Se connecter
                </button>
              </motion.div>



          }
        </AnimatePresence>

        <AnimatePresence>
          {error &&
            <motion.div
              className="errorBox p-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
              exit={{ opacity: 0, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
            >
              <h1>
                {error}
              </h1>
            </motion.div>
          }
        </AnimatePresence>
      </form>
    </div>
  );
}
