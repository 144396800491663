import { Link, useNavigate } from 'react-router-dom';
import { FaLink, FaSearch } from 'react-icons/fa';

import React, { useState,useCallback, useEffect } from 'react';
import Loading from '../Components/Loading';

import NotFoundUserPage from './NotFoundUserPage';
import Consultation from '../Components/Consultation';
import { useDispatch, useSelector } from 'react-redux';
import api from '../config';
import LoadingPage from '../Components/LoadingPage';

export default function TodConsultations() {

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token); 
  const navigate=useNavigate()// Assuming 'auth' is your authentication slice

 
  const [loading, setLoading] = useState(true);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [consultations, setConsultation] = useState(null);
  const [newConsultations, setNewConsultation] = useState(null);
  const [counter,setCounter]=useState(null)


  
  useEffect(() => {

    const fetchData = async () => {
      try {
        
        const response = await api.get(`consultations/current`, {
          headers: {
            token: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Handle the response as needed
       
        setConsultation(response.data)
        setCounter(response.data.length)
      } catch (error) {
        navigate("/consultations");
      }finally {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, [dispatch, token, navigate]);
 
  

  const handleSearch = useCallback(() => {
    if (!consultations) return; // Ensure data is available
  
    if (searchTerm.trim() !== '') {
     
      const filteredData =consultations?.filter((consultation) =>{
     return consultation.price?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    consultation.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    consultation.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    consultation.createdAt.toLowerCase().includes(searchTerm.toLowerCase())
      });
      setCounter(filteredData.length)
      setNewConsultation(filteredData);
    } else {
      setNewConsultation(null); // Clear newData if search term is empty
      setCounter(consultations.length)
    }
  }, [searchTerm, consultations]);

 // ...

return (
  <div>
  {
    loading ? <LoadingPage />:
  <div className={`flex mx-2 flex-col consultations`}>
    <div className={`flex items-center justify-between gap-1 mx-3 p-2 url my-2 max-sm:mx-0 max-sm:p-1 max-sm:flex-col`}>
      <div className={`max-sm:order-1 flex items-center gap-1 mx-3 p-2 my-2 max-sm:mx-0 max-sm:p-1`}>
        <FaLink className={`icon`} />
        <Link to="/add-consultation" className={`p-3 max-sm:text-sm`}>Ajouter un nouvel consultation</Link>
      </div>
      <div className='max-sm:order-3 bg-white counter max-sm:w-full max-sm:flex max-sm:justify-center max-sm:items-center my-2 '>
        {consultations?.length > 1 ? <span className=' w-40% max-sm:text-exsm active-color'>{counter} consultations Trouvés
        </span> : <span className=' w-40% max-sm:text-exsm active-color'>{counter} consultation Trouvé
        </span>}
      </div>
      <div className={`w-50% flex items-center gap-2 max-sm:w-full max-sm:my-2 max-sm:order-2`}>

        <input
          type="text"
          placeholder={`Chercher...`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className=' max-sm:text-exsm'
        />
         <button onClick={handleSearch} className='max-sm:text-exsm bg-white active-color'><FaSearch/></button>
      </div>
      <div></div>
    </div>
    {
     (newConsultations|| consultations)   ? (
        <div className={`flex flex-wrap`}>
          { (newConsultations|| consultations)?.map((consultation, index) => (
            <Consultation key={index} consultation={consultation}  />
          ))}
          <div>
            {loading &&<Loading />}
          </div>
        </div>
      ) : (
        <NotFoundUserPage message="Aucun consultation Trouvé"/>
      )
    }
  </div>
  }
  </div>
);

}
