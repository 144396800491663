import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../config';
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate } from 'react-router-dom';

export default function AddPatient() {
  const token = useSelector((state) => state.auth.token);
  const navigate=useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    cin: '',
    gender: '', // Added for Situation Familiale
    mutualStatus: '', // Updated for Situation Mutuelle
    img:'',
    address:"",
    dateOfBirth:'',
    familySituation:""
  });
  useEffect(() => {
    // Check if any input value is empty
    
  }, [formData]);

  const handleInputChange = (e) => {
    setErrors(false)
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function calculateAge(dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
  
    let age = currentDate.getFullYear() - birthDate.getFullYear();
  
    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
  
    return age;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Check for empty input values
    const hasEmptyInput = Object.entries(formData).some(([key, value]) => {
      // Exclude the 'img' field from the check
      if (key === 'img') return false;
      // Check if the value is empty
      return value.trim() === '';
    });
    
  
    // Set errors state based on whether there are empty inputs
    setErrors(hasEmptyInput);
  
    // Check if there are any errors
    if (!hasEmptyInput) {
      try {
        // Define the img function
        const img = () => {
          const age = calculateAge(formData.dateOfBirth);
          const genderPrefix = formData.gender === 'Féminin' ? '-woman' : '-man';
          return `/img/icons/${
            age <= 10 ? `0-10${genderPrefix}` :
            age <= 20 ? `10-20${genderPrefix}` :
            age <= 30 ? `20-30${genderPrefix}` :
            age <= 40 ? `30-40${genderPrefix}` :
            `old${genderPrefix}`
          }.png`;
        };
  
        // Create a new patient object with input values
        const newPatient = {
          firstName: formData.firstname,
          lastName: formData.lastname,
          phone: formData.phone,
          mutualSituation: formData.mutualStatus,
          cin: formData.cin,
          gender: formData.gender,
          dateOfBirth: formData.dateOfBirth,
          address: formData.address,
          familySituation:formData.familySituation,
          img: img(),
        };
        
        // Make the API request to create a new patient
        await api.post('patients/create', newPatient, {
          headers: {
            token: `Bearer ${token}`, // Correct the header key to 'Authorization'
          },
        });
  
        // Navigate to the patient list page after successful creation
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate("/patient");
      } catch (error) {
        console.error('Error creating patient:', error);
      }
    }
  
    // Set isSubmitting state back to false after form submission
    setIsSubmitting(false);
  };
  

  return (
    <div className="flex-col addPatient mx-3 my-1 flex p-4 items-center justify-center max-sm:min-w-full max-sm:m-0 max-sm:mt-3 min-w-max">
      <form className="row flex flex-col p-4 backdrop-blur-md items-center justify-center w-60% max-sm:w-full mt-1" onSubmit={handleSubmit}>
        <h1 className='text-3xl max-sm:text-sm p-4 m-3'>Ajouter un nouvel Patient</h1>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="firstname" className='w-30%'>
            Nom
          </label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder='Ahmed ...'
          />
        </div>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="lastname" className='w-30%'>
            Nom de famille
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder='Ghani...'
          />
        </div>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="lastname" className='w-30%'>
          Date de naissance
          </label>
          <input
            type="date"
            id="lastname"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleInputChange}
            placeholder='Ghani...'
          />
        </div>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="address" className='w-30%'>
            Adresse
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            placeholder='Anfa Rue 21 ...'
          />
        </div>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="phone" className='w-30%'>
            Téléphone
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder='0600000000...'
          />
        </div>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="cin" className='w-30%'>
            Carte d'identité nationale
          </label>
          <input
            type="text"
            id="cin"
            name="cin"
            value={formData.cin}
            onChange={handleInputChange}
            placeholder='BK6RTER...'
          />
        </div>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="maritalStatus" className='w-30%'>
          le genre
          </label>
          <select
            id="maritalStatus"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
          >
            <option>Sélectionner</option>
            <option value="Féminin">Féminin</option>
            <option value="Masculin">Masculin</option>
          </select>
        </div>
        <div className='w-full flex gap-3 p-3 items-center max-sm:text-xs'>
  <label htmlFor="maritalStatus" className='w-30%'>
    Situation Familiale
  </label>
  <select
    id="maritalStatus"
    name="familySituation"
    value={formData.familySituation}
    onChange={handleInputChange}
  >
    <option value="">Sélectionner</option>
    <option value="Marié(e)">Marié(e)</option>
    <option value="Célibataire">Célibataire</option>
  </select>
</div>

        <div className='w-full flex gap-3 p-3 items-center max-sm:text-exsm'>
          <label htmlFor="mutualStatus" className='w-30%'>
            Situation Mutuelle
          </label>
          <input
            type="text"
            id="mutualStatus"
            name="mutualStatus"
            value={formData.mutualStatus}
            onChange={handleInputChange}
            placeholder='CNSS...'
          />
        </div>
        {
          isSubmitting ?
            <div className='flex justify-center w-full'><div className="loader max-sm:w-30px max-sm:border-4"></div>
            </div>
            :
            <div className='flex justify-center  w-full'>
              <motion.button
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                transition={{ duration: 0.2 }}
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray"
                }}
                type="submit"
                className="bg-blue-500 text-white p-2 rounded my-4 max-sm:text-exsm w-50% "
                disabled={isSubmitting}
              >
                Ajouter
              </motion.button>
            </div>

        }
         <AnimatePresence>
          {errors &&
            <motion.div
              className="errorBox p-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
              exit={{ opacity: 0, transition: { duration: 0.3 } }} // Duration set to 0.3 seconds
            >
              <h1>
              Veuillez remplir tous les champs avant de soumettre le formulaire.
              </h1>
            </motion.div>
          }
        </AnimatePresence>
      </form>
    </div>
  );
}