import React, { useEffect } from 'react'
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';



export default function Message({message,i,setName,name}) {
  const user = useSelector((state) => state.auth.user); 

  useEffect(() => {
    // Check if name is not set and the message and user match conditions
    if (!name && message && user._id !== message.sender) {
      setName(message.firstName);
    }
  }, [name, message, setName, user._id]);

 
    return (
      <motion.div
       
        className={`p-5 ${user._id === message.sender ? "from" : "to"}`}
      >

         <span>{`${user._id === message.sender ? "Moi" : message.firstName}`}</span>
        <div>{message.text}</div>
      </motion.div>
    );
  }
