import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import SideNav from './Components/SideNav';
import TopNav from './Components/TopNav';
import PhoneSideNav from './Components/PhoneSideNav';
import Messages from './Routes/Messages';



const RouteParm = () => {

  const [openSideNav, setOpenSideNav] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  

  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.token !== null); // Replace this with your actual selector

  const handellOpenSideNav = () => {
    setOpenSideNav(!openSideNav);
  };

  // Use useEffect to handle the redirect when the component mounts
  useEffect(() => {
    if (!isAuth) {
      navigate('/login');
    }
  }, [isAuth, navigate]);

  // Return null to avoid rendering anything else after the redirect
  if (!isAuth) {
    return null;
  }



  return (
    <>

      <SideNav />
    
     
    {openMessages && <Messages setOpenMessages={setOpenMessages} openMessages={openMessages} />}
    {!openMessages && <Messages/>}
    {openSideNav && <PhoneSideNav openSideNav={openSideNav} handellOpenSideNav={handellOpenSideNav} />}

      <div className='main flex backdrop-blur-sm bg-white/30 ml-53px max-sm:ml-0'>
        <div className='main-container flex backdrop-blur-md bg-white/30 w-full h-screen ml-25px max-sm:ml-0'>



          <div className={'main-continer flex flex-col w-full max-sm:w-full '}>
            <TopNav  handellOpenSideNav={handellOpenSideNav} openSideNav={openSideNav} setOpenMessages={setOpenMessages} openMessages={openMessages} />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default RouteParm;
