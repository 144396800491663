// store.js


import authReducer from './Auth/authReducer';
import notificationReducer from './notification/notificationReducer'
import { configureStore } from '@reduxjs/toolkit'



export const store = configureStore({
  reducer: {
    auth: authReducer,
    notification:notificationReducer,
    
  } 
})